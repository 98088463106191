import styled from 'styled-components'
import { Container } from 'reactstrap'

export * from './Header'
export * from './Layout'
export * from './LinkList'
export * from './PublishedData'
export * from './RichText'

export const SinglePageContainer = styled(Container)`
  padding: 0 2rem 4rem;
`
