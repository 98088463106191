import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

type FixedImg = {
  fixed: {
    src: string
  }
}

type SEOQuery = {
  favicon16x16: FixedImg
  favicon32x32: FixedImg
  site: {
    siteMetadata: {
      author: string
      defaultLang: string
      defaultTitle: string
      description: string
      favicon16: string
      favicon32: string
      keywords: string[]
      siteUrl: string
      titleTemplate: string
    }
  }
}

export type SEOProps = {
  lang?: string
  title?: string
}

export const Seo: React.FC<SEOProps> = ({ lang, title }) => {
  const data = useStaticQuery<SEOQuery>(graphql`
    query SEO {
      favicon16x16: contentfulAsset(
        contentful_id: { eq: "7cewmOI6USBD6GRras8nvn" }
      ) {
        fixed(height: 16, width: 16, quality: 100) {
          src
        }
      }
      favicon32x32: contentfulAsset(
        contentful_id: { eq: "7cewmOI6USBD6GRras8nvn" }
      ) {
        fixed(height: 32, width: 32, quality: 100) {
          src
        }
      }
      site {
        siteMetadata {
          author
          defaultLang: lang
          defaultTitle: title
          description
          keywords
          siteUrl
          titleTemplate
        }
      }
    }
  `)

  const seo = {
    author: data.site.siteMetadata.author,
    description: data.site.siteMetadata.description,
    favicon16: data.favicon16x16.fixed.src,
    favicon32: data.favicon32x32.fixed.src,
    keywords: data.site.siteMetadata.keywords.join(),
    lang: lang || data.site.siteMetadata.defaultLang,
    title: title || data.site.siteMetadata.defaultTitle,
    titleTemplate: data.site.siteMetadata.titleTemplate,
    url: data.site.siteMetadata.siteUrl,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: seo.lang,
      }}
      meta={[
        { name: 'author', content: seo.author },
        { name: 'description', content: seo.description },
        { name: 'keywords', content: seo.keywords },
        { property: 'og:description', content: seo.description },
        { property: 'og:title', content: seo.title },
        {
          property: 'og:type',
          content: 'website',
        },
        { property: 'og:url', content: seo.url },
      ]}
      link={[
        {
          rel: 'icon',
          href: 'data:,',
        },
        {
          rel: 'shortcut icon',
          type: 'image/png',
          sizes: '16x16',
          href: seo.favicon16,
        },
        {
          rel: 'shortcut icon',
          type: 'image/png',
          sizes: '32x32',
          href: seo.favicon32,
        },
      ]}
      script={[
        {
          id: 'Cookiebot',
          src: 'https://consent.cookiebot.com/uc.js',
          'data-cbid': '07b90fa4-8666-443b-97c2-ceb64919d2da',
          'data-blockingmode': 'auto',
          type: 'text/javascript',
        },
      ]}
      title={seo.title}
      titleTemplate={seo.titleTemplate}
    />
  )
}
