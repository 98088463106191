import React, { useCallback, useMemo, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'
import { ScrollUp } from './partial'
import { ROUTES } from '../../../../../constants/routes'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

interface NavigtionQuery {
  logo: {
    fluid: FluidObject
    title: string
  }
}

interface NavigationItem {
  name: string
  url: string
  external?: boolean
}

export type NavigationProps = {
  location?: ROUTES
  slug?: string
}

const items = (location?: ROUTES, slug?: string): NavigationItem[] => {
  switch (location) {
    case ROUTES.HOME:
      return [
        {
          name: 'CHI SIAMO',
          url: '#about',
        },
        {
          name: 'BRANCHE',
          url: '#groups',
        },
        {
          name: 'ISCRIZIONE',
          url: '#enrollment',
        },
        {
          name: 'EVENTI',
          url: '#events',
        },
        {
          name: 'FOTO',
          url:
            'https://drive.google.com/drive/folders/1T2RMu1jQRgV9ayevWRznXmc7nwX_1YwQ?usp=sharing',
          external: true,
        },
        {
          name: 'UTILI',
          url: '/utils',
        },
        {
          name: 'CONTATTI',
          url: '#contact',
        },
      ]
    case ROUTES.BOARD:
      return [
        {
          name: 'BRANCA',
          url: `/group/${slug}`,
        },
        {
          name: 'HOME',
          url: '/',
        },
      ]
    case ROUTES.GROUP:
      return [
        {
          name: 'BACHECA',
          url: `/board/${slug}`,
        },
        {
          name: 'HOME',
          url: '/',
        },
      ]
    default:
      return [
        {
          name: 'HOME',
          url: '/',
        },
      ]
  }
}

export const Navigation: React.FC<NavigationProps> = ({ location, slug }) => {
  const data = useStaticQuery<NavigtionQuery>(graphql`
    query Navigation {
      logo: contentfulAsset(
        id: { eq: "5dc75b1a-4409-58b1-af32-9ae8e2f9d694" }
      ) {
        fluid(maxHeight: 60, maxWidth: 60, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  const expandNav = useMemo(() => (location === ROUTES.HOME ? 'lg' : 'sm'), [
    location,
  ])

  return (
    <StyledNavbar sticky="top" light expand={expandNav}>
      <Container>
        <StyledNavbarBrand href="/#">
          <StyledImg
            alt={data.logo.title}
            fluid={data.logo.fluid}
            loading="eager"
          />
          <Title>ROMA 24</Title>
        </StyledNavbarBrand>
        <StyledNavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <StyledNav navbar>
            {items(location, slug).map(item => (
              <NavItem key={item.name}>
                {item.external ? (
                  <StyledOutboundLink
                    className="nav-link"
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </StyledOutboundLink>
                ) : (
                  <StyledNavLink href={item.url}>{item.name}</StyledNavLink>
                )}
              </NavItem>
            ))}
          </StyledNav>
        </Collapse>
      </Container>
      <ScrollUp />
    </StyledNavbar>
  )
}

const StyledNavbar = styled(Navbar)`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 2rem;
  transition: 0.5s ease-in-out;
`

const StyledImg = styled(Img)`
  display: inline-block;
  height: 3rem;
  width: 3rem;
`

const Title = styled.span`
  color: var(--roma24-blue);
  font-size: 2rem;
  font-weight: bold;
  margin-left: 1rem;
  vertical-align: middle;
`

const StyledNavbarBrand = styled(NavbarBrand)`
  display: flex;
`

const StyledNavbarToggler = styled(NavbarToggler)`
  margin-left: auto;
`

const StyledNav = styled(Nav)`
  margin-left: auto;
`

const StyledNavLink = styled(NavLink)`
  color: var(--roma24-red) !important;
  display: block;
  font-size: 1.2rem;
  font-weight: bold;

  &:focus,
  &:hover {
    color: var(--roma24-blue) !important;
  }
`

const StyledOutboundLink = styled(OutboundLink)`
  color: var(--roma24-red) !important;
  display: block;
  font-size: 1.2rem;
  font-weight: bold;

  &:focus,
  &:hover {
    color: var(--roma24-blue) !important;
  }
`
