import React from 'react'
import { UncontrolledAlert } from 'reactstrap'
import styled from 'styled-components'

interface MessageProps {
  error: boolean
}

export const Message: React.FC<MessageProps> = props => (
  <StyledAlert color={props.error ? 'danger' : 'success'}>
    {props.error ? 'Error message' : 'Success message'}
  </StyledAlert>
)

const StyledAlert = styled(UncontrolledAlert)`
  position: sticky;
  top: 5rem;
  z-index: 1020;
`
