import React from 'react'
import Moment from 'react-moment'
import styled from 'styled-components'
import { BoardLink } from './partial'

interface PostPublishedDataProps {
  createdAt: string
  groups?: [
    {
      initials: string
      name: string
      slug: string
    }
  ]
}

export const PublishedData: React.FC<PostPublishedDataProps> = ({
  createdAt,
  groups,
}) => {
  return (
    <StyledDiv>
      {`Pubblicato `}
      <Moment fromNow titleFormat="LLLL" withTitle>
        {createdAt}
      </Moment>
      <>
        {groups ? (
          <>
            <>{groups.length === 1 ? ` nella bacheca ` : ` nelle bacheche `}</>
            <>
              {groups.map(group => (
                <BoardLink
                  initials={group.initials}
                  key={group.initials}
                  name={group.name}
                  slug={group.slug}
                />
              ))}
            </>
          </>
        ) : (
          <> </>
        )}
      </>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  color: lightslategray;
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
`
