import React from 'react'
import {
  Footer,
  Message,
  Navigation,
  NavigationProps,
  Seo,
  SEOProps,
} from './partial'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { emailSentSelector } from '../../../state/selectors'
import { ROUTES } from '../../../constants/routes'

type LayoutProps = {
  navigation?: NavigationProps
  seo?: SEOProps
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  navigation,
  seo = {},
}) => {
  const isHome = navigation?.location === ROUTES.HOME
  const emailSent = useSelector(emailSentSelector)

  const content = (
    <>
      <Seo {...seo} />
      <Navigation {...navigation} />
      {isHome && emailSent && <Message error={emailSent.error} />}
      {children}
      <Footer stayDown={!isHome} />
    </>
  )

  return isHome ? content : <StyledDiv>{content}</StyledDiv>
}

const StyledDiv = styled.div`
  display: block;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  padding-bottom: 4rem; /* height of your footer */
  position: relative;
`
