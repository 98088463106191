import React, { ReactNode } from 'react'
import { Block, Inline } from '@contentful/rich-text-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const DEFAULT_CONTENTFUL_LANG = 'it-IT'

function renderEntryHiperlink(
  urlRoot: string,
  node: Block | Inline,
  children: ReactNode
): ReactNode {
  return (
    <A
      to={`/${urlRoot}/${node.data.target.fields.slug[DEFAULT_CONTENTFUL_LANG]}`}
    >
      {children}
    </A>
  )
}

// eslint-disable-next-line react/no-multi-comp
export function parseEntryHiperlink(
  node: Block | Inline,
  children: ReactNode
): ReactNode {
  switch (node.data.target.sys.contentType.sys.id) {
    case 'group':
      return renderEntryHiperlink('group', node, children)
    case 'post':
      return renderEntryHiperlink('news', node, children)
    default:
      return (
        <span className="font-weight-bold">
          Unknown entry-hiperlink with id:{' '}
          {node.data.target.sys.contentType.sys.id}
        </span>
      )
  }
}

const A = styled(Link)`
  color: red;
  font-weight: bold;
`
