import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

type BoardLinkProps = {
  initials: string
  name: string
  slug: string
}

export const BoardLink: React.FC<BoardLinkProps> = ({
  initials,
  name,
  slug,
}) => {
  return (
    <StyledLink title={name} to={`/board/${slug}`}>
      {initials}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  padding-inline-end: 0.5rem;
  white-space: nowrap;
`
