import React from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { ROUTES } from '../../../../../constants/routes'

interface FooterQuery {
  footer: {
    nodes: [
      {
        text: string
      }
    ]
  }
}

type FooterProps = {
  stayDown: boolean
}

export const Footer: React.FC<FooterProps> = ({ stayDown }) => {
  const data = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      footer: allContentfulFooter(limit: 1) {
        nodes {
          text
        }
      }
    }
  `).footer.nodes[0]

  return (
    <StyledFooter position={stayDown ? 'absolute' : 'relative'}>
      <StyledContainer>
        <Link to={ROUTES.COOKIE_DECLARATION}>Dichiarazione dei cookie</Link>
        <StyledP>{data.text}</StyledP>
      </StyledContainer>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer<{ position: string }>`
  background-color: var(--roma24-blue);
  bottom: 0;
  color: whitesmoke;
  padding: 1.2rem;
  position: ${(props): string => props.position};
  width: 100%;
`

const StyledContainer = styled(Container)`
  text-align: center;

  & > a {
    color: lightslategray;
  }
`

const StyledP = styled.p`
  font-size: small;
  font-weight: bold;
  margin: auto;
`
