import React from 'react'
import {
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

interface LinkedListProps {
  icon: IconProp
  items: [
    {
      name: string
      link: string
      caption?: string
    }
  ]
  title: string
}

export const LinkList: React.FC<LinkedListProps> = ({ icon, items, title }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <StylidList flush>
        {items.map(item => (
          <StyledLink
            href={item.link}
            key={item.name}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledLi>
              <span>
                <StyledItem>{item.name}</StyledItem>
                {item.caption && <Caption>{item.caption}</Caption>}
              </span>
              <FontAwesomeIcon icon={icon} />
            </StyledLi>
          </StyledLink>
        ))}
      </StylidList>
    </Container>
  )
}

const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
`

const StylidList = styled(ListGroup)`
  padding: 2rem 12rem;

  @media only screen and (max-width: 1024px) {
    padding: 2rem 0;
  }
`

const StyledLi = styled(ListGroupItem)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const StyledLink = styled(OutboundLink)`
  color: black;
`

const StyledItem = styled(ListGroupItemHeading)`
  display: inline;
`

const Caption = styled(ListGroupItemText)`
  color: lightslategrey;
`
