import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ScrollUp: React.FC = () => {
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const handleScroll = (): void => {
      // When the user scrolls down lower than 20px from the top of the document, show the button
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return (): void => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [setVisible])

  return (
    <CustomLink
      display={isVisible ? 'block' : 'none'}
      href="#"
      title="Saliamo!"
    >
      <FontAwesomeIcon icon="chevron-up" />
    </CustomLink>
  )
}

const CustomLink = styled.a<{ display: string }>`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20%;
  bottom: 1rem;
  color: whitesmoke;
  display: ${(props): string => props.display};
  font-size: 1.2rem;
  padding: 0.4rem 0.8rem;
  position: fixed;
  right: 1rem;
  z-index: 99;

  &:hover {
    background: black;
    color: whitesmoke;
  }
`
