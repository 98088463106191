import React from 'react'
import styled from 'styled-components'

export type HeaderProps = {
  color?: string
  title: string
  subtitle?: string
}

export const Header: React.FC<HeaderProps> = props => (
  <StyledHeader>
    <Title color={props.color}>{props.title}</Title>
    {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
    <Underline />
  </StyledHeader>
)

export const StyledHeader = styled.header`
  margin-bottom: 2rem;
  text-align: center;
`

const Title = styled.h1<{ color?: string }>`
  color: ${(props): string => props.color || 'black'};
  font-size: 2.2rem;
  font-weight: bold;
`

const Subtitle = styled.p`
  color: lightslategray;
  font-size: 1.2rem;
  font-style: italic;
`

const Underline = styled.div`
  background-color: black;
  height: 5px;
  margin: 0 auto;
  width: 50px;
`
