/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/display-name */
import React from 'react'
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'
import { parseEntryHiperlink } from './render'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

interface RichTextProps {
  content: Document
  align?: string
}

export const RichText: React.FC<RichTextProps> = ({
  align = 'left',
  content,
}) => {
  return (
    <>
      {documentToReactComponents(content, {
        renderNode: {
          [BLOCKS.LIST_ITEM]: (_node, children) => (
            <Li align={align}>{children}</Li>
          ),
          [BLOCKS.PARAGRAPH]: (_node, children) => (
            <P align={align}>{children}</P>
          ),
          [INLINES.ENTRY_HYPERLINK]: parseEntryHiperlink,
          [INLINES.HYPERLINK]: (node, children) => (
            <StyledOutboundLink
              href={node.data.uri}
              target="_blank"
              rel="noopener"
            >
              {children}
            </StyledOutboundLink>
          ),
        },
      })}
    </>
  )
}

const Li = styled.li<{ align: string }>`
  font-size: 1.2rem;
  text-align: ${({ align }) => align};
`

const P = styled.p<{ align: string }>`
  font-size: 1.2rem;
  text-align: ${({ align }) => align};
`

const StyledOutboundLink = styled(OutboundLink)`
  color: var(--roma24-red);
  font-style: italic;

  &: hover {
    color: var(--roma24-blue);
  }
`
